import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6e937473"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "fixed-assets-container"
};
var _hoisted_2 = {
  class: "round-swiper1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_top = _resolveComponent("top");

  var _component_fixed_assets_b = _resolveComponent("fixed-assets-b");

  var _component_fixed_assets_f = _resolveComponent("fixed-assets-f");

  var _component_fixed_assets_a = _resolveComponent("fixed-assets-a");

  var _component_fixed_assets_c = _resolveComponent("fixed-assets-c");

  var _component_fixed_assets_d = _resolveComponent("fixed-assets-d");

  var _component_common_round_swiper1 = _resolveComponent("common-round-swiper1");

  var _component_fixed_assets_g = _resolveComponent("fixed-assets-g");

  var _component_common_recommend = _resolveComponent("common-recommend");

  var _component_fixed_assets_e = _resolveComponent("fixed-assets-e");

  var _component_common_footer1 = _resolveComponent("common-footer1");

  var _component_common_back_top = _resolveComponent("common-back-top");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_top), _createVNode(_component_fixed_assets_b), _createVNode(_component_fixed_assets_f), _createVNode(_component_fixed_assets_a), _createVNode(_component_fixed_assets_c), _createVNode(_component_fixed_assets_d, {
    d: $data.d1
  }, null, 8, ["d"]), _createVNode(_component_common_round_swiper1, {
    t: "使用范围与特点",
    c: "专为写字楼等室内综合办公场所量身打造，以灵活简易的操作实现轻量化固定资产管理。",
    images: $data.cList,
    btnStyle: {
      background: '#F4F7FA'
    }
  }, null, 8, ["images"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_fixed_assets_d, {
    d: $data.d2
  }, null, 8, ["d"]), _createVNode(_component_common_round_swiper1, {
    t: "使用范围与特点",
    c: "专门为工厂、车间、仓库等物资种类繁多、仓储需求高、作业环境复杂的盘点场景而研发。",
    images: $data.cList1,
    reverse: true,
    containerStyle: {
      background: 'none'
    }
  }, null, 8, ["images"])]), _createVNode(_component_fixed_assets_g), _createVNode(_component_common_recommend, {
    title: $data.recommend.title,
    list: $data.recommend.list
  }, null, 8, ["title", "list"]), _createVNode(_component_fixed_assets_e), _createElementVNode("div", {
    style: _normalizeStyle({
      margin: '2rem 0 .4rem',
      height: '.02rem',
      background: '#E5E6EB'
    })
  }, null, 4), _createVNode(_component_common_footer1), _createVNode(_component_common_back_top)]);
}